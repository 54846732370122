import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import withAnalytics from 'utils/analytics';

class MainLayout extends React.Component {
  render() {
    return (
      <div className="container">
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

const WrappedMainLayout = (Component) => {
  const AnalyticsComponent = withAnalytics(Component);

  return () => (
    <MainLayout>
      <AnalyticsComponent />
    </MainLayout>
  );
};

export default WrappedMainLayout;
