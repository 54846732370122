import React from 'react';

import GradientWrapper from '../../components/GradientWrapper';
import withMainLayout from 'layouts/main/view';

// use a specific token symbol if configured, otherwise fall back to
// a generic description.
const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL || "tokens";

class AboutPage extends React.Component {
  render() {
    return (
      <article className="narrow-contentx">
        <GradientWrapper style={{maxWidth: '90rem'}}>
          <div className={'about-content'}>
            <div className="nav-button">
                <a href="/">Projects</a>
            </div>
            <div className="titlebar">
              <h2 className="titlebar__title">
                ABOUT
              </h2>
            </div>
            <div className="about-top">
            <p>
              Scout is a social experiment for <a href="https://www.thelao.io">The LAO</a> attempting to see if we can democratically
              curate a list of the best projects in need of funding in the blockchain ecosystem. Scout is built on top of Alpine's original code base for TCRP. 
            </p>
            <p>We run two bots:</p>
            <ul>
              <li>
                <a href={ "https://twitter.com/" + process.env.REACT_APP_PARTY_BOT_HANDLE }><b>@{process.env.REACT_APP_PARTY_BOT_HANDLE}</b></a> retweets
                every Twitter handle that is on the list.
              </li>
              <li>
                <a href={ "https://twitter.com/" + process.env.REACT_APP_VIP_BOT_HANDLE }><b>@{process.env.REACT_APP_VIP_BOT_HANDLE}</b></a> where the
                democracy (or chaos) happens. This bot announces recent nominations, votes,
                and challenges to the Twitter users on the list. Anybody can interact with
                this bot via direct message to help curate the list.
              </li>
            </ul>
            <p>
              Behind the scenes, this is built on top of a <a href="https://medium.com/@ilovebagels/token-curated-registries-1-0-61a232f8dac7">Token Curated Registry </a>
              living on the Ethereum Rinkeby Testnet. Essentially, it ensures that anyone
              can participate in the list and receive a token
              (which has no monetary value!) as a result of performing actions that help
              curate the list.
            </p>
            <p>
              <b>If you're not interested in participating further in the list and just want
                to receive the feed, you can follow <a href={ "https://twitter.com/" + process.env.REACT_APP_PARTY_BOT_HANDLE }>@{process.env.REACT_APP_PARTY_BOT_HANDLE}</a> and stop here. </b>
              Otherwise, keep reading.
            </p>

            <h2>What is a TCR?</h2>
            <p>
              A token curated registry (TCR) is a list that is managed by a public group of
              token-holders. There is a process to nominate new members to the list, and if
              there are no challenges to a nomination, the nominated member is added.  Once
              on the list, a member can be challenged at any time if they are to remain on
              the list or be kicked off.
            </p>
            <p>
              If a challenge is submitted during a nomination period, there is a voting
              process on whether to keep or kick the member.  If they are kept, they are
              added to the list. If they are kicked, they do not make it to the list and
              can be renominated.
            </p>
            <p>
              If a challenge is submitted after a member has been successfully nominated,
              there is a voting process on whether to keep or kick the member. If they are
              kept, the member stays on the list.  If they are kicked, they are removed
              from the list and can be renominated.
            </p>
            <p>
              Nominations and challenges cost a set amount of tokens. During a challenge,
              voting requires a user to lock up a variable amount of tokens. The more you
              vote with, the more of a say you're given. 
            </p>

            <h2>Roles</h2>
            <ul>
              <li><b>Nominators</b> nominate users or bystanders to be a list member.</li>
              <li><b>Challengers</b> challenge list nominates or existing list members.</li>
              <li><b>Voters</b> put weight behind a challenge.</li>
              <li><b>List Members</b> are retweeted by {process.env.REACT_APP_PARTY_BOT_HANDLE}.</li>
            </ul>
            <h2>How Do I Join?</h2>
            <ul>
              <li>Follow <a target="_blank" href={ "https://twitter.com/" + process.env.REACT_APP_VIP_BOT_HANDLE } rel="noopener noreferrer">@{process.env.REACT_APP_VIP_BOT_HANDLE}</a>.</li>
              <li>Tweet <b>"Hey @{process.env.REACT_APP_VIP_BOT_HANDLE} get started"</b>.</li>
              <li>Wait for confirmation via a DM and follow the provided instructions.</li>
            </ul>

            <h2>What Do I Receive?</h2>
            <p>
              Fame and internet points. What more could you want?  This is just a social
              experiment to see if we can hide the ugly blockchain stuff behind a familiar
              setting like Twitter, and test some assumptions about TCRs.
            </p>

            <h2>The Current Registry</h2>
            <p>
              The current registry including users being nominated, users being challenged,
              and current members can be found at <a href="/leaderboard">the leaderboard</a>.
            </p>

            <h2>How Do I Nominate a Project to Be on the List?</h2>
            <p>
              You can vote via DM to the bot (just leave out the mention) or publicly with
              the following command:
            </p>
            <p>
              <b>"@{process.env.REACT_APP_VIP_BOT_HANDLE} nominate @name"</b>
            </p>
            <p>
              Make sure you have enough tokens to nominate by checking your balance with the
              "balance" command (via DM).
            </p>
            <h2>If You Don't Like a Project, Challenge Them</h2>
            <p>
              At any point, a project can be challenged, whether it's during the nomination
              period or after they are added to the list. The command is:
            </p>

            <p>
              <b>"@{process.env.REACT_APP_VIP_BOT_HANDLE} challenge @name"</b>
            </p>
            <p>
              Make sure you have enough tokens to challenge by checking your balance with
              the "balance" command.
            </p>

            <h2>How Do I Vote on a Challenge?</h2>
            <ul>
              <li>
                Make sure you have a sufficient amount of tokens by typing "balance" in a
                DM to @{process.env.REACT_APP_VIP_BOT_HANDLE}.
              </li>
              <li>
                Next, find the handle of the user you wish to vote for (or against), and
                tell the bot "vote @user keep" or "vote @user kick."
              </li>
              <li>
                If all goes well the bot should message you back letting you know that your
                transaction was submitted to the blockchain.
              </li>
            </ul>
            <h2>Increasing Your Vote Weight</h2>
            <ul>
              <li>
                By default, voting has a weight (strength) of 50 tokens.
              </li>
              <li>
                Vote weight refers to how many tokens you're putting behind your vote. A
                higher vote weight strengthens either your kick or keep position to ward
                off opponents.
              </li>
            </ul>

            <h2>Staking For Dummies</h2>
            <ul>
              <li>
                Nominators have their 500 {tokenSymbol} locked with
                nominees until they're potentially challenged and removed - in which case
                they lose their tokens.
              </li>
              <li>
                Challengers have their 500 {tokenSymbol} locked until
                any challenge period they've initiated ends. If they are unsuccessful, they
                lose a percentage of their tokens.
              </li>
              <li>
                Voters have their vote weight locked until a challenge period they're
                voting on ends.
              </li>
            </ul>

            <h2>Risk and Rewards of Voting and Challenging</h2>
            <ul>
              <li>
                If challengers are successful in challenging a listing, the challenger and
                voters on their side receive a portion of the original nominator's tokens.
              </li>
              <li>
                If a challenge is unsuccessful, the original nominator receives tokens from
                the challenger.
              </li>
            </ul>

            <h2>How Do I Receive More Tokens?</h2>
            <p>
              There is an active faucet of 100 {tokenSymbol} per day which can be accessed by DMing
              "faucet" to @{process.env.REACT_APP_VIP_BOT_HANDLE}.
            </p>

            <h2>Commands</h2>
            <p>
              Once you've registered with the bot and received your first distribution of
              {tokenSymbol} , you can send direct messages to {process.env.REACT_APP_VIP_BOT_HANDLE}
              in order to nominate, challenge, and vote on listings.
            </p>
            <ul>
              <li>
                <b>balance</b> – Returns the current balance in your wallet.
              </li>
              <li>
                <b>faucet</b> – Sends you 50 free {tokenSymbol}  per day!
              </li>
              <li>
                <b>nominate [handle]</b> – Nominates a Twitter handle to be on the list.
              </li>
              <li>
                <b>challenge [handle]</b> – Challenges a nomination or currently listing on
                the list.
              </li>
              <li>
                <b>status</b> – Responds with a status of each of the listings you've created, 
                in addition to letting you know the status of your Twitter handle's listing.
              </li>
              <li>
                <b>vote [handle] [kick/keep] [weight - default 50]</b> – Submits a vote for
                a currently open challenge against the given handle. Note that their handle
                and "kick" or "keep" are required, however a weight is not and is set to 50
                by default. The maximum weight of your vote is determined by the number of
                tokens you currently have locked up for voting (see below).
              </li>
              <li>
                <b>vote-balance</b> – Returns the balance of tokens you currently have
                locked up for voting on challenges.
              </li>
              <li>
                <b>vote-deposit</b> – Allows you to lock up tokens for voting on
                challenges, therefore increasing the maximum weight you can place on your
                votes.
              </li>
              <li>
                <b>vote-withdraw</b> – Allows you to release tokens locked up for voting on
                challenges, therefore decreasing the maximum weight you can place on your
                votes.
              </li>
            </ul>
          </div>
          </div>
        </GradientWrapper>
      </article>
    );
  }
}

export default withMainLayout(AboutPage);
