import React from 'react';
import TwitterSVG from '../../src/assets/svg/TwitterSVG';
import GitHubSVG from '../../src/assets/svg/GitHubSVG';
import MediumSVG from '../../src/assets/svg/MediumSVG';
import TelegramSVG from '../../src/assets/svg/TelegramSVG';
import DiscourseSVG from '../../src/assets/svg/DiscourseSVG';

class SocialMedia extends React.Component {
  render = () => {
    return (
      <div className={'social-media-wrapper'}>
        <a
          href="https://twitter.com/thelaoofficial"
          target="_blank"
          rel="noopener noreferrer">
          <TwitterSVG />
        </a>
        <a
          href="https://github.com/openlawteam/TheLAO"
          target="_blank"
          rel="noopener noreferrer">
          <GitHubSVG />
        </a>
        <a
          href="https://medium.com/@thelaoofficial"
          target="_blank"
          rel="noopener noreferrer">
          <MediumSVG />
        </a>
        <a
          href="https://t.me/joinchat/FLvdBBVz3o9pfi-rfd9BqQ"
          target="_blank"
          rel="noopener noreferrer">
          <TelegramSVG />
        </a>
        <a
          href="https://discourse.thelao.io"
          target="_blank"
          rel="noopener noreferrer">
          <DiscourseSVG />
        </a>
      </div>
    );
  };
}

export default SocialMedia;
