import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import ReactGA from 'react-ga';

import LeaderboardPage from './pages/leaderboard/view';
import AboutPage from './pages/about/view';

import "./assets/scss/global.scss";

// TODO(openlaw): test mode is hardcoded to true for now,
// since we haven't updated the GA slug to match something we
// have setup. If we want to re-enable GA later, change this
// value, and set testMode true/false such that it's only
// true when running in production (and not CI).
ReactGA.initialize('UA-131202545-2', { testMode: true });

class App extends React.Component {
  render() {
    return (
      <Router>
        <Fragment>
          <Route exact path="/" component={LeaderboardPage} />
          <Route exact path="/about" component={AboutPage} />
        </Fragment>
      </Router>
    );
  }
}

export default App;
