import React, {useState} from 'react';
import ReactModal from 'react-modal';
import {Transition} from 'react-transition-group';

import TimesSVG from '../assets/svg/TimesSVG';
import HamburgerSVG from '../assets/svg/HamburgerSVG';
import Logo from './Logo';

import m from '../assets/scss/modules/modal.module.scss';

// see: http://reactcommunity.org/react-transition-group/transition
const duration = 200;

const defaultStyle = {
  transition: `0.1s`
};

const transitionOpeningStyles = {
  entering: {left: '-300px'},
  entered: {left: 0},
  exiting: {left: 0, opacity: 0},
  exited: {left: '-300px', opacity: 0}
};

const transitionClosingStyles = {
  entering: {left: 0, opacity: 1},
  entered: {left: '-300px', opacity: 1},
  exiting: {left: '-300px', opacity: 1},
  exited: {left: 0, opacity: 1}
};

export default function Header(props) {
  const [shouldShowMenuModal, setShouldShowMenuModal] = useState(false);
  const [transitionStyles, setTransitionStyles] = useState(
    transitionOpeningStyles
  );

  function handleMenuModalClose(close) {
    // delay transition for closing
    if (close) {
      setShouldShowMenuModal(close);
      setTransitionStyles(transitionOpeningStyles);
    } else {
      setTransitionStyles(transitionClosingStyles);
      const closeMenu = setTimeout(() => setShouldShowMenuModal(close), 500);
      return () => clearTimeout(closeMenu);
    }
  }

  return (
    <>
    <header>
      <Logo />
      <div className="top-nav">
        <ul className="nav_menu">
          <li><a href="https://www.thelao.io/members">MEMBERS</a></li>
          <li><a href="https://www.thelao.io/proposals">INVESTMENTS</a></li>
          <li><a className="active" href="/">SCOUT</a></li>
          <li><a href="https://www.thelao.io/apply">APPLY</a></li>
          <li><a href="https://www.thelao.io/mentors">MENTORS</a></li>
          <li>
          <div
            className={m['menu-modal-hamburger']}
            aria-label="Menu"
            aria-controls="navigation"
            onClick={event => {
              event.preventDefault();
              handleMenuModalClose(true);
            }}>
            <HamburgerSVG />
          </div>
          </li>
        </ul>
      </div>
      </header>
      {/** MODAL MENU */}
      <ReactModal
        ariaHideApp={false}
        className={`${m['modal-menu-content']}`}
        isOpen={shouldShowMenuModal}
        onRequestClose={() => {
          handleMenuModalClose(false);
        }}
        overlayClassName={`${m['modal-menu-overlay']}`}
        role="dialog"
        style={
          {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}}
        }>
        <Transition appear in={shouldShowMenuModal} timeout={duration}>
          {transition => (
            <nav role="navigation" id="navigation">
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[transition]
                }}
                className={`${m['modal-menu-container']}`}>
                <button
                  className={`${m['modal-menu-close']}`}
                  onClick={event => {
                    event.preventDefault();
                    handleMenuModalClose(false);
                  }}>
                  <TimesSVG />
                </button>
                <div
                  style={{width: '8rem', marginTop: '1rem', marginLeft: '2rem'}}>
                  <Logo size={'small'} />
                </div>
                <ul className={`${m['menu']}`}>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <a className="contribute" href="https://www.thelao.io/contribute">
                      <span aria-label="Money mouth emoji" role="img">
                        🤑 Contribute
                      </span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <a className="contribute" href="https://www.thelao.io/apply">
                      <span aria-label="Unicorn emoji" role="img">
                        🦄 Apply
                      </span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <a
                      className="members"
                      href='https://www.thelao.io/members'>
                      <span>Members</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <a className="proposals" href="https://www.thelao.io/proposals">
                      <span>Investments</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <a className="proposals" href="https://www.thelao.io/mentors">
                      <span>Mentors</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      handleMenuModalClose(false);
                    }}>
                    <a
                      href="https://docs.thelao.io"
                      rel="noopener noreferrer"
                      target="_blank">
                      <span>Help</span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          )}
        </Transition>
      </ReactModal>
    </>
  );
}


