import React from 'react';

import l from '../assets/scss/modules/logo.module.scss';

export default function Logo(props) {
  const classNameSizes = {
    the: props.size ? `${l[`the--${props.size}`]}` : '',
    lao: props.size ? `${l[`lao--${props.size}`]}` : ''
  };

  return (
    <div className={`${l.logo}`}>
      <div className={`${l.the} ${classNameSizes.the}`}>THE</div>
      <div className={`${l.lao} ${classNameSizes.lao}`}>LAO</div>
    </div>
  );
}
