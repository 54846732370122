import React from 'react';

import s from '../assets/scss/modules/gradientwrapper.module.scss';

export default function GradientWrapper(props) {
  return (
    <div className={s['gradient-wrapper']} style={props.style}>
      <div>{props.children}</div>
    </div>
  );
}
