import React, {Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
import ChallengeListing from './challenge-listing';
import NominateListing from './nominate-listing';
import WhitelistListing from './whitelist-listing';

export default class CurrentMembers extends React.Component {

  wrap = (text) => {
    return text
      .split(' ')
      .map((word, i) => {
        if (i !== 0 && i % 7 === 0) return `${word}<br>`;
        else return word;
      })
      .join(' ');
  }

  render() {
    const {whitelisted, nominated, challenged} = this.props;

    const total = whitelisted.length + nominated.length + challenged.length;

    return (
      <Fragment>
        <ReactTooltip multiline />
        <div className="nav-button">
          <a href="/about">About</a>
        </div>
        <div className="titlebar">
        <h2 className="titlebar__title">
          SCOUT
        </h2>
        </div>
        <div className="lists-container">
          <p className="list-stats">
           <span role="img" aria-label="Emoji, money face">🤑</span> Find the best blockchain startups and nominate them for funding. 
           The community has identified {total} projects as potential LAO investment targets <span role="img" aria-label="Emoji, money face">🤑</span>
          </p>
          <div className="lists">
          <div className="list">
            <h3>
            <span role="img" aria-label="Top projects">🏆</span> Top Projects
              <span className="help" data-tip={this.wrap("These projects have been identified as projects that should be funded by The LAO.")}>?</span>
            </h3>
            {whitelisted.length === 0 && (
              <p className="none">None</p>
            )}
            <ul className="members">
              {whitelisted.map((listing) => <WhitelistListing listing={listing} key={listing.listingHash} />)}
            </ul>
          </div>
          <div className="list">
            <h3>
            <span role="img" aria-label="Nominated projects">💍</span> Nominated
              <span className="help" data-tip={this.wrap("These projects have been twitter-scouted as good blockchain projects for funding. In 12 hours, they're added. During this nomination period anybody may submit a challenge to block them from being nominated.")}>?</span>
            </h3>
            {nominated.length === 0 && (
              <p className="none">None</p>
            )}
            <ul className="members">
              {nominated.map((listing) => <NominateListing listing={listing} key={listing.listingHash} />)}
            </ul>
          </div>
          <div className="list">
            <h3>
            <span role="img" aria-label="Challenged projects">⚔️ </span>Challenged
              <span className="help" data-tip={this.wrap("These projects have either been nominated or are already on the list, but somebody has initiated a challenge to remove them. During the voting period you may choose to keep or kick them.")}>?</span>
            </h3>
            {challenged.length === 0 && (
              <p className="none">None</p>
            )}
            <ul className="members">
              {challenged.map((listing) => <ChallengeListing listing={listing} key={listing.listingHash} />)}
            </ul>
          </div>
        </div>
        </div>
      </Fragment>
    );
  }
}
