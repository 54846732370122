import React from 'react';

import SocialMedia from './SocialMedia';

import s from '../assets/scss/modules/footer.module.scss';

const year = () => new Date().getFullYear();

export default function Footer() {
  return (
    <footer className={`${s.wrap} grid--fluid`}>
      <div className="grid__row">
        <div className={`${s.left} grid__col-lg-6 grid__col-12`}>
          <span className={s.item}>&copy; {year()} The LAO, LLC</span>

          <a className={s.item} href="https://docs.thelao.io/">
            Help
          </a>
          <a className={s.item} href="https://www.thelao.io/privacy">
            Privacy
          </a>
          <a className={s.item} href="mailto:hello@thelao.io">
            Message Us
          </a>
        </div>

        <div className={`${s.right} grid__col-lg-6 grid__col-12`}>
          <SocialMedia />
        </div>
      </div>
    </footer>
  );
}
