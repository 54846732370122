import React, {Fragment} from 'react';

import withMainLayout from 'layouts/main/view';
import CurrentMembers from './components/current-members';
import GradientWrapper from '../../components/GradientWrapper';
class LeaderboardPage extends React.Component {
  constructor() {
    super();
    this.state = {
      tcr_address: "TBD",
      challenged: [],
      whitelisted: [],
      nominated: [],
    };
  }

  componentDidMount() {
    let url = process.env.REACT_APP_LIST_API_ENDPOINT;

    fetch(url)
    .then(results => {
     return results.json()
     }).then(data => {
      this.setState({
        tcr_address: data.tcr_address || process.env.REACT_APP_TCRP_REGISTRY_ADDRESS,
        whitelisted: sortByAppEnd(data.whitelisted),
        challenged: sortChallenges(data.challenges),
        nominated: sortByAppEnd(data.applications),
      })
    })

    const sortByAppEnd = (listings) => {
      const sorted = listings.sort((a, b) => {
        if (a.application_ended_at < b.application_ended_at) {
          return -1;
        } else if (a.application_ended_at > b.application_ended_at) {
          return 1;
        }
        return 0;
      }); 
      return sorted;
    }

    const sortChallenges = (listings) => {
      const now = + new Date();
  
      const isReveal = x => Date.parse(x.challenge.commit_ends_at) >= now;
  
      const sorted = listings.sort((a, b) => {
        if (isReveal(a) && !isReveal(b)) {
          return -1;
        } else if (!isReveal(a) && isReveal(b)) {
          return 1;
        } else if (isReveal(a) && isReveal(b) && a.challenge.reveal_ends_at < b.challenge.reveal_ends_at) {
          return -1;
        } else if (isReveal(a) && isReveal(b) && a.challenge.reveal_ends_at > b.challenge.reveal_ends_at) {
          return 1;
        } else if (isReveal(a) && isReveal(b) && a.challenge.reveal_ends_at === b.challenge.reveal_ends_at) {
          return 0;
        } else if (a.challenge.commit_ends_at < b.challenge.commit_ends_at) {
          return -1;
        } else if (a.challenge.commit_ends_at > b.challenge.commit_ends_at) {
          return 1;
        }
      return 0;
      });
      return sorted;
    }
  }

  render() {
    return (
      <Fragment>
        <GradientWrapper>
          <article>
            <CurrentMembers nominated={this.state.nominated} challenged={this.state.challenged} whitelisted={this.state.whitelisted} />
          </article>
          </GradientWrapper>

          <article className="tcr-info">
            Sourcing data at <span className="code">{this.state.tcr_address}</span> (Rinkeby network).
          </article>
      </Fragment>
    );
  }
}

export default withMainLayout(LeaderboardPage);
